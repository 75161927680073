<template>
  <b-nav :vertical="vertical" :class="classesComputed">
    <b-nav-item
      v-for="(item,index) in listIterator(navListComputed)"
      :key="index"
      @click="routeTo(item.path)"
      :active="checkPaths(item)"
    >
      <div>
        <span
          v-if="isNotEmpty(item.icon)"
          class="nav-item__icon"
        >
          <font-awesome-icon
            :icon="item.icon"
            class="fa-fw"
          />
        </span>
        <span class="nav-item__desc">
          {{ item.name }}
        </span>
      </div>
    </b-nav-item>
    <b-nav-item
      href='https://mfsolvency.it/contatti/'
      target="_blank"
      class="mt-md-auto"
    >
      <div>
        <span
          class="nav-item__icon"
        >
          <font-awesome-icon
            icon="headset"
            class="fa-fw"
          />
        </span>
        <span class="nav-item__desc">
          Hai bisogno di aiuto?
        </span>
      </div>
    </b-nav-item>
  </b-nav>
</template>

<script>

import { isNotEmpty, isPresent } from '@/utils/validators';

export default {
  name: 'NavItems',
  components: { },
  data() {
    return {
      navList: [],
    };
  },
  props: {
    classes: String,
    vertical: Boolean,
  },
  computed: {
    activityStatus() {
      if (this.isCompanyProfile) {
        return this.$store.getters['subject/activityCompanyStatus'];
      }
      return this.$store.getters['subject/activityPersonStatus'];
    },
    isOperative() {
      return this.activityStatus.operative;
    },
    isNotOperative() {
      return this.activityStatus.notOperative;
    },
    isCompanyProfile() {
      const currentProfile = this.$store.getters['subject/currentCompany'];
      return isNotEmpty(currentProfile);
    },
    getUserType() {
      // console.log('getUserType');
      if (this.isCompanyProfile) return 'building';
      // console.log('getUserType user');
      return 'user';
    },
    userStatsLabel() {
      if (this.isCompanyProfile) return 'Scoring Aziendale';
      return 'Scoring Personale';
    },
    itemDashboard() {
      return {
        path: '/dashboard-utente',
        name: 'Dashboard',
        icon: 'home',
      };
    },
    itemProducts() {
      return [
        {
          path: '/product-visure',
          name: 'Visure',
          icon: 'fa-file-contract',
        },
        {
          path: '/product-bilanci',
          name: 'Bilanci',
          icon: 'fa-file-contract',
        },
        {
          path: '/product-report',
          name: 'Report',
          icon: 'fa-file-contract',
        },
      ];
    },
    itemRequests() {
      return {
        path: '/user-analysis-history',
        name: 'I miei acquisti',
        icon: 'list',
        optPaths: [
          'user-analysis-detail',
        ],
      };
    },
    itemClickSearch() {
      return {
        path: '/click-search',
        name: 'Click Report',
        icon: 'chart-pie',
        optPaths: [
          'mf-solvency',
        ],
      };
    },
    itemSearch() {
      return {
        path: '/profile-search',
        name: 'Le mie ricerche',
        icon: 'chart-pie',
        optPaths: [
          '/company-search',
        ],
      };
    },
    itemReport() {
      return {
        path: '/user-report-history',
        name: this.reportLabel,
        icon: 'paper-plane',
        optPaths: [
          'user-report-detail',
          'create-report',
        ],
      };
    },
    itemUser() {
      return {
        path: '/user-data',
        name: 'Profilo',
        icon: this.getUserType || 'user',
      };
    },
    itemStats() {
      if (!this.isCompanyProfile) {
        return {
          path: '/user-stats',
          name: this.userStatsLabel,
          icon: 'qrcode',
        };
      }
      return {};
    },
    itemCompany() {
      if (this.isCompanyProfile) {
        return {
          path: '/my-profile',
          name: 'La mia Azienda',
          icon: 'qrcode',
          optPaths: ['user-stats'],
        };
      }
      return {};
    },
    itemWallet() {
      return {
        path: '/wallet',
        name: 'Wallet',
        icon: 'wallet',
        optPaths: ['wallet-topup'],
      };
    },
    itemInvoice() {
      if (this.isCompanyProfile) {
        return {
          path: '/cr-invoice',
          name: 'CR Invoice',
          icon: 'file-invoice-dollar',
          optPaths: ['user-invoice-detail'],
        };
      }
      return {};
    },
    itemCdv() {
      return {
        path: '/club-dei-virtuosi',
        name: 'Club dei Virtuosi',
        icon: 'award',
      };
    },
    navListOperative() {
      return [
        this.itemDashboard,
        this.itemSearch,
        ...this.itemProducts,
        this.itemClickSearch,
        this.itemRequests,
        this.itemInvoice,
        this.itemCompany,
        this.itemUser,
        this.itemWallet,
      ];
    },
    navListNotOperative() {
      return [
        this.itemDashboard,
        this.itemSearch,
        ...this.itemProducts,
        this.itemClickSearch,
        this.itemUser,
        this.itemWallet,
      ];
    },
    navListWaitApproval() {
      return [
        this.itemDashboard,
        this.itemUser,
      ];
    },
    navListComputed() {
      // console.log('activityStatus', this.activityStatus);
      if (this.isOperative) return this.navListOperative;
      if (this.isNotOperative) return this.navListNotOperative;
      return this.navListWaitApproval;
    },
    classesComputed() {
      const baseClasses = 'main-nav';
      return isNotEmpty(this.classes) ? `${baseClasses} ${this.classes}` : baseClasses;
    },
    reportLabel() {
      if (this.isCompanyProfile) return 'Segnalazioni & Feedback';
      return 'Segnalazioni';
    },
    routePath() {
      return this.$route.path;
    },
  },
  methods: {
    isNotEmpty,
    routeTo(r) {
      this.$router.replace(r);
    },
    checkPaths(item) {
      const p = this.routePath;
      let pathActive = false;
      // const optPath = false;
      // const pathCheck = false;
      // console.log('item', item, p, item.optPaths);
      if (item.path === p) return true;
      if (isNotEmpty(item.optPaths)) {
        // pathActive = true;
        // console.log('pathActive', pathActive, item.optPaths);
        item.optPaths.forEach((path) => {
          // console.log('each path', path, p, p.split('/')[1], path === p.split('/')[1]);
          if (path === p.split('/')[1]) pathActive = true;
        });
      }
      return pathActive;
      // return item.path === p;
    },
    listIterator(list) {
      return list.filter((item) => isPresent(item.path));
    },
  },
};
</script>

<style scoped>

</style>
